<template>
  <f7-page
    no-swipeback
    :page-content="false"
  >
    <navigation-top
      :title="title"
      :back="{name: 'edl', params: {localId}}"
    >
      <template #global-actions>
        <f7-input
          type="select"
          :value="locale"
          @change="switchLanguage"
          @focus="showFullLanguageLabel = true"
          @blur="showFullLanguageLabel = false"
        >
          <option
            v-for="lang in availableLanguages"
            :key="'lang_'+lang.locale"
            :value="lang.locale"
          >
            {{ dynamicLanguageLabel(lang) }}
          </option>
        </f7-input>
      </template>
    </navigation-top>
    <navigation-bottom current="new" />

    <f7-page-content ref="recap">
      <edl-recap-convention-content
        :edl="edl"
        :info="info"
        :scroll="currentScroll"
        @ready="isReady = true"
        @track="trackScroll"
        @skip="goToRecap"
      />
      <f7-row>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
        <f7-col
          xsmall="60"
          medium="40"
          width="90"
          class="margin-top margin-bottom"
        >
          <f7-button
            large
            raised
            fill
            @click="goToRecap"
            :text="isReady ? 'Suivant' : 'Passer cette étape'"
          >
          </f7-button>
        </f7-col>
        <f7-col
          xsmall="20"
          medium="30"
          width="5"
        />
      </f7-row>
    </f7-page-content>
  </f7-page>
</template>

<script>
import firewall from "../../../mixins/firewall";
import NavigationTop from "../../../layout/NavigationTop.vue";
import NavigationBottom from "../../../layout/NavigationBottom.vue";
import {mapActions, mapGetters} from "vuex";
import _ from "lodash";
import EdlRecapConventionContent from "@/components/pages/Edl/EdlRecap/EdlRecapConventionContent.vue";
import {unobserve} from "@/store/helper";
import errors from "@/components/mixins/errors";
import language from "@/components/mixins/language";

export default {
    name: "EdlRecapConvention",
    components: {
      EdlRecapConventionContent,
      NavigationTop,
      NavigationBottom,
    },
    mixins: [firewall, errors, language],
    props: {
      localId: {
        type: String,
        default: null
      },
      f7router: {
        type: Object,
        default: null
      }
    },
    data: function () {
      return {
        preloader: null,
        edl: null,
        info: null,
        currentScroll: 0,
        throttledHandleScroll: null,
        isReady: false
      }
    },
    computed: {
      ...mapGetters('edlPersist', [
        'getCurrentLocalEdl'
      ]),
      title: function () {
        let title = 'Récapitulatif - Convention de sortie';
        if(this.info && this.info.conventionSortieManuelle) {
          title += ' (manuelle)';
        }
        return title;
      }
    },
    mounted: function () {
      let id = +this.localId; //converts to number
      this.setCurrentLocalEdlId(id);
      this.setup();
    },
    beforeUnmount: function () {
      this.$refs.recap.$el.removeEventListener('scroll', this.throttledHandleScroll);
    },
    methods: {
      ...mapActions('edlPersist', [
        'setCurrentLocalEdlId',
        'updateCurrentLocalEdlInfo'
      ]),
      setup: function () {
        let edl = unobserve(this.getCurrentLocalEdl);
        this.edl = edl.edl;
        this.info = _.omit(edl, ['edl']);
      },
      goToRecap: function () {
        this.f7router.navigate({name: 'edlRecap', params: {'localId': this.localId}}, {reloadAll: true});
      },
      trackScroll: function () {
        this.throttledHandleScroll = _.throttle(this.handleScroll, 200);
        this.$refs.recap.$el.addEventListener('scroll', this.throttledHandleScroll);
      },
      handleScroll: function () {
        this.currentScroll = this.$refs.recap.$el.scrollTop;
      },
      switchLanguage: function (event) {
        this.info.locale = event.target.value !== "" ? event.target.value : null;
        this.updateInfo();
        this.showFullLanguageLabel = false;
      },
      updateInfo: function () {
        this.updateCurrentLocalEdlInfo(this.info);
      },
    }
  }
</script>

<style lang="scss" scoped>
  .current-module {
    position: fixed;
    height: 50px;
    width: 100%;
    z-index: 100;
    .header-module {
      margin: 0;
    }
  }
  .header-module {
    background: var(--f7-theme-color-grey);
    font-size: 34px;
  }
  .header-entry {
    font-size: 20px;
  }
  .header-sub-entry {
    color: black;
  }
  .entry {
    margin-bottom: 25px;
  }
  .entry .sub-entry:not(:last-child) {
    margin-bottom: 25px;
  }
  .rate-name {
    font-weight: bold;
    font-size: 16px;
  }
  .rate {
    font-size: 16px;
  }
  .photos {
    padding: 10px;
    border-radius: 5px;
    background-color: var(--f7-theme-color-grey);
    :deep(.block-header) {
      width: 100%;
    }
    .photo {
      float: left;
      height: auto;
      img {
        width: 100%; /* or any custom size */
        height: auto;
        object-fit: contain;
      }
    }
  }
  .row.photos-context {
    margin-top: 10px;
  }
  .nl2br {
    white-space: pre-line;
  }
</style>
