import axios from 'axios';
import store from "@/store";
import _ from "lodash";

const getAPIUrl = () => {
  switch(window.location.host) {
    case process.env.VUE_APP_DEV_HOST:
      return process.env.VUE_APP_DEV_API_URL;
    case process.env.VUE_APP_PROD_HOST:
      return process.env.VUE_APP_PROD_API_URL;
    default:
      return process.env.VUE_APP_LOCAL_API_URL;
  }
}

const API = getAPIUrl();
const PLATFORM_API = process.env.VUE_APP_PLATFORM_API_URL;

const getJwtTokenInLocalStorage = () => {
  let persistedStore = JSON.parse(localStorage.getItem('vuex'));
  return persistedStore && persistedStore.security.jwtToken !== undefined ? persistedStore.security.jwtToken : null;
};

export const login = (login, password) => {
  return axios.post(API+'/login', {
    'username': login,
    'password': password
  });
};

export const refreshLogin = (login, refreshToken) => {
  return axios.post(API+'/login/refresh', {
    'refresh': refreshToken
  });
};

const authenticateAxiosRequest = (config) => {
  if(config.params?.token === undefined //Case Platform, don't use Authorization header (send it as param)
    && config.url !== API+'/login/refresh' //Don't send during refresh as it conflicts at api security level
    && !isLimitedAuthorization(config.headers)) //Case Limited Authorization, header should have already been set
  {
    //Add JWT Token to every request
    const token = getJwtTokenInLocalStorage();
    if (token) {
      config = Object.assign(config, {
        headers: {
          Authorization: "Bearer " + token
        }
      });
    }
  }

  return config;
}

//Authentication on all requests
axios.interceptors.request.use(authenticateAxiosRequest, function (error) {
  return Promise.reject(error);
});

// Refresh JWT if response http code is 401
axios.interceptors.response.use( (response) => {
  // Return a successful response back to the calling service
  return response;
}, (error) => {
  // Return any error which is not due to authentication back to the calling service
  if (error.response?.status !== 401) {
    return new Promise((resolve, reject) => {
      reject(error);
    });
  }

  console.log(error.response.status, error.config.url);

  // Logout user if token refresh didn't work or user is disabled
  if (error.config.url === API+'/login/refresh' || isLimitedAuthorization(error.config.headers)) {
    console.log('refresh failed');
    return new Promise((resolve, reject) => {
      store.dispatch('security/resetJwtToken')
        .then(() => {
          console.log('should logout');
          error.isLimited = isLimitedAuthorization(error.config.headers);
          reject(error);
        });
    });
  }

  // Refresh JWT then recall initial request
  return store.dispatch('security/refreshLogin')
    .then(() => {
      store.dispatch('me/fetchMe').then(() => { //Refresh user first
        return new Promise((resolve, reject) => {
          axios
            .request(authenticateAxiosRequest(error.config))
            .then(response => { //Call initial request again
              console.log('test');
              resolve(response);
            }).catch((error) => {
              reject(error);
            });
        });
      });
    });
});

const isLimitedAuthorization = (headers) => {
  return _.has(headers, 'Authorization') && headers.Authorization.includes("Limited");
}

const withAuthorizationToken = (type, value) => {
  let headers = {
    Authorization: type + " " + value
  };

  return {headers};
}

/**** USER *****/
export const health = () => {
  return axios.head(API+'/me');
};

export const me = (edlId = null, publicToken = null) => {
  if(edlId && publicToken) {
    return axios.get(API+'/me', withAuthorizationToken('Limited', edlId + "#" + publicToken));
  } else {
    return axios.get(API+'/me');
  }
};

export const updateMe = (id, payload) => {
  return axios.patch(API+'/me', payload);
};

export const updatePassword = (id, password) => {
  return axios.patch(API+'/user/' + id + '/password', {password});
};

export const updatePlatformToken = (id, platformToken) => {
  return axios.patch(API+'/user/' + id + '/platform-token', {platformToken});
};

export const users = (groupId = null) => {
  return axios.get(API+'/user'+(groupId ? '?group='+groupId : ''));
};

export const user = (id) => {
  return axios.get(API+'/user/' + id);
};

export const createUser = (payload) => {
  return axios.post(API+'/user', payload);
};

export const updateUser = (id, payload) => {
  return axios.patch(API+'/user/' + id, payload);
};

export const deleteUser = (id) => {
  return axios.delete(API+'/user/' + id);
};

export const getUiDefaultLegalText = (type) => {
  return axios.get(API+'/ui/default-legal-text/'+type);
}

/**** Profile *****/
export const createProfile = (name) => {
  return axios.post(API+'/profile', {name});
};

export const deleteProfile = (id) => {
  return axios.delete(API+'/profile/' + id);
};

export const defaultProfile = (id) => {
  return axios.put(API+'/profile/' + id + '/default');
};

export const updateProfile = (id, payload) => {
  return axios.put(API+'/profile/' + id, payload);
};

export const updatePasscode = (id, passcode) => {
  return axios.patch(API+'/profile/' + id + '/passcode', {passcode});
};

/**** GROUP *****/
export const userGroups = (groupId = null) => {
  return axios.get(API+'/user-group'+(groupId ? '?group='+groupId : ''));
};

export const userGroup = (id) => {
  return axios.get(API+'/user-group/' + id);
};

export const createUserGroup = (payload) => {
  return axios.post(API+'/user-group', payload);
};

export const updateUserGroup = (id, payload) => {
  return axios.put(API+'/user-group/' + id, payload);
};

export const deleteUserGroup = (id) => {
  return axios.delete(API+'/user-group/' + id);
};

export const userGroupProfiles = (id) => {
  return axios.get(API+'/user-group/' + id + '/profiles');
};

/**** AUTO-EDLS (admin) ****/
export const autoEdls = () => {
  return axios.get(API+'/auto-edl');
};

export const createAutoEdl = (payload) => {
  return axios.post(API+'/auto-edl', payload);
};

export const deleteAutoEdl = (id) => {
  return axios.delete(API+'/auto-edl/' + id);
};

/**** EDL *****/
export const edls = (params) => {
  return axios.get(API+'/edl', { params: params });
};

export const edl = (id) => {
  return axios.get(API+'/edl/'+id);
};

export const generateEdlPdf = (id) => {
  return axios.post(API+'/edl/'+id+'/pdf/generate');
};

export const isGeneratedEdlPdf = (id) => {
  return new EventSource(API+'/sse/edl/'+id+'/pdf/has-generated');
};

export const generateConventionPdf = (id, publicToken = null) => {
  if(publicToken) {
    console.log({publicToken});
    return axios.post(API+'/edl/'+id+'/convention-pdf/generate', null, withAuthorizationToken('Limited', id + "#" + publicToken));
  } else {
    return axios.post(API+'/edl/'+id+'/convention-pdf/generate');
  }
};

export const isGeneratedConventionPdf = (id) => {
  return new EventSource(API+'/sse/edl/'+id+'/convention-pdf/has-generated');
};

export const downloadEdlPdf = (id) => {
  return axios.get(API+'/edl/'+id+'.pdf', { responseType: 'blob' });
};

export const downloadEdlConventionPdf = (id) => {
  return axios.get(API+'/edl/'+id+'/convention.pdf', { responseType: 'blob' });
};

export const downloadEdlHtml = (id) => {
  return axios.get(API+'/edl/'+id+'.html');
};

export const downloadEdlConventionHtml = (id) => {
  return axios.get(API+'/edl/'+id+'/convention.html');
};

export const createEdl = (payload) => {
  return axios.post(API+'/edl', payload);
};

export const deleteEdl = (id) => {
  return axios.delete(API+'/edl/'+id);
};

export const sendEdl = (id) => {
  return axios.put(API+'/edl/'+id+'/send');
};

export const edlStats = (group_id, start_date, end_date) => {
  let params = {
    group_id: group_id,
    start_date: start_date,
    end_date: end_date,
  };

  return axios.get(API+'/edl/stats', { params: params });
};

export const updateEdlComment = (id, comment) => {
  return axios.patch(API+'/edl/' + id + '/comment', {comment});
};

export const updateEdlShowCosts = (id, showCosts) => {
  return axios.patch(API+'/edl/' + id + '/show-costs', {showCosts});
};

export const updateEdlLocale = (id, locale) => {
  return axios.patch(API+'/edl/' + id + '/locale', {locale});
};

/**** COSTING ****/
export const getCosting = (edlId, publicToken = null) => {
  if(publicToken) {
    return axios.get(API+'/edl/'+edlId+'/costing', withAuthorizationToken('Limited', edlId + "#" + publicToken));
  } else {
    return axios.get(API+'/edl/'+edlId+'/costing');
  }
};

export const updateCosting = (costingId, data, edlId, publicToken = null) => {
  if(publicToken) {
    return axios.patch(API+'/edl/costing/'+costingId, data, withAuthorizationToken('Limited', edlId + "#" + publicToken));
  } else {
    return axios.patch(API+'/edl/costing/'+costingId, data);
  }
};

export const getUserCalibration = (userId, edlId = null, publicToken = null) => {
  if (edlId && publicToken) {
    return axios.get(API+'/calibration/user/'+userId, withAuthorizationToken('Limited', edlId + "#" + publicToken));
  } else {
    return axios.get(API+'/calibration/user/'+userId);
  }
};

export const getUserGroupCalibration = (userGroupId) => {
  return axios.get(API+'/calibration/group/'+userGroupId);
};

export const getCalibrationConfig = (edlId = null, publicToken = null) => {
  if (edlId && publicToken) {
    return axios.get(API+'/calibration/config', withAuthorizationToken('Limited', edlId + "#" + publicToken));
  } else {
    return axios.get(API+'/calibration/config');
  }
};

export const updateCalibration = (calibrationId, data) => {
  return axios.patch(API+'/calibration/'+calibrationId, data);
};

export const createUserGroupCalibration = (userGroupId, data) => {
  return axios.post(API+'/calibration/group/'+userGroupId, data);
};

export const createUserCalibration = (userId, data) => {
  return axios.post(API+'/calibration/user/'+userId, data);
};

export const deleteCalibration = (calibrationId) => {
  return axios.delete(API+'/calibration/'+calibrationId);
};

//HISTORY
export const getHistory = (edlId) => {
  return axios.get(API+'/edl/'+edlId+'/history');
}

export const downloadHistoryCsv = (edlId) => {
  return axios.get(API+'/edl/'+edlId+'/history.csv', { responseType: 'blob' });
}

//DDH PLATFORM (Espace client)
export const getPlatformCases = (platformToken) => {
  return axios.get(PLATFORM_API + '/API/api_dossierList.php', {params: {token: platformToken}});
}

export default {
  health,
  login,
  refreshLogin,
  me,
  updateMe,
  updatePassword,
  updatePlatformToken,
  users,
  user,
  createUser,
  updateUser,
  deleteUser,
  userGroups,
  userGroup,
  createUserGroup,
  updateUserGroup,
  deleteUserGroup,
  userGroupProfiles,
  createProfile,
  deleteProfile,
  defaultProfile,
  updateProfile,
  updatePasscode,
  autoEdls,
  createAutoEdl,
  deleteAutoEdl,
  edls,
  edl,
  createEdl,
  deleteEdl,
  generateEdlPdf,
  isGeneratedEdlPdf,
  generateConventionPdf,
  isGeneratedConventionPdf,
  downloadEdlPdf,
  downloadEdlConventionPdf,
  downloadEdlHtml,
  downloadEdlConventionHtml,
  sendEdl,
  edlStats,
  updateEdlComment,
  updateEdlShowCosts,
  updateEdlLocale,
  getCosting,
  updateCosting,
  getUserCalibration,
  getUserGroupCalibration,
  getCalibrationConfig,
  updateCalibration,
  createUserGroupCalibration,
  createUserCalibration,
  deleteCalibration,
  getHistory,
  downloadHistoryCsv,
  getPlatformCases,
  getUiDefaultLegalText
};
